export function toUppercase(word) {
  const str = word || '';
  return str?.toUpperCase();
}
export function toLowercase(word) {
  const str = word || '';
  return str?.toLowerCase().replace(/_/g, ' ');
}

export const onNumberKeyDownValidation = e =>
  ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();

export const onDecimalKeyDownValidation = e =>
  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

export const onPaybillNoKeyDownValidation = e =>
  ['e', 'E', '-', '.', '$'].includes(e.key) && e.preventDefault();

export function removeUnderscoreFromString(str = '') {
  return str?.replace(/_/g, ' ');
}

export function hyphenateString(str = '') {
  return str?.replace(/\s/g, '-')?.toLowerCase();
}

export const strictCurrencyFormatter = num => {
  if (isNaN(num)) return '0.00';
  const number = Number(num);
  return new Intl.NumberFormat('en-KE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })?.format(number);
};

// eslint-disable-next-line consistent-return
export function isEmail(val) {
  const regEmail =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!regEmail.test(val)) {
    return 'Email is invalid';
  }
}
