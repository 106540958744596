import * as React from 'react';
import ScrollToTop from 'components/ScrollToTop';
import queryString from 'query-string';
import { isNull } from 'utils/helpers';

import { BrowserRouter as Router } from 'react-router-dom';

import { BASE_API_ENDPOINT } from 'utils/constants/env-keys';
import { useAuth } from 'containers/Auth/context';
import { GlobalContextProvider } from './GlobalContext';

const AuthentictedApp = React.lazy(() =>
  import(
    /* webpackChunkName: "AuthentictedApp" */ 'containers/App/AuthentictedApp'
  ),
);

const UNAuthenticatedApp = React.lazy(() =>
  import(
    /* webpackChunkName: "UNAuthenticatedApp" */ 'containers/App/UNAuthenticatedApp'
  ),
);

// function getCookie(cname) {
//   // from https://www.w3schools.com/js/js_cookies.asp
//   const name = `${cname}=`;
//   const decodedCookie = decodeURIComponent(document.cookie);
//   const ca = decodedCookie.split(';');
//   for (let i = 0; i < ca.length; i++) {
//     let c = ca[i];
//     while (c.charAt(0) == ' ') {
//       c = c.substring(1);
//     }
//     if (c.indexOf(name) == 0) {
//       return c.substring(name.length, c.length);
//     }
//   }
//   return '';
// }

function App() {
  const auth = useAuth();
  // const queryParams = queryString.parse(location?.search);
  // React.useEffect(() => {
  //   if (isNull(localStorage.getItem('isAtPortal'))) {
  //     localStorage.setItem('isAtPortal', false);
  //   }
  // }, []);

  // const clientID = '951887aa-45d8-41aa-8f68-cd35c01dd140';
  // const csrf_token = getCookie('csrf_tkn'); // +'-corrupt-';
  // const refresh_token = getCookie('r_tkn');
  // const access_token = getCookie('a_tkn');

  // console.log('document', getCookie('csrf_tkn'));

  // React.useEffect(() => {
  //   const redirectURI = 'http://127.0.0.1:3000/oauth/callback';
  //   // const clientID = '949990e4-c22a-4433-ab14-d945be460aa1';

  //   console.log('window.location.pathname', window.location.pathname);

  //   if (window.location.pathname !== '/oauth/callback') {
  //     console.log('weAreHere', window.location.pathname);
  //     window.location.href = `${BASE_API_ENDPOINT}/api/oauth/login?client_id=${clientID}&redirect_uri=${redirectURI}&response_type=code&scope=&state=`;
  //   }

  //   if (window.location.pathname === '/oauth/callback') {
  //     console.log('csrf_token', {
  //       refresh_token: refresh_token,
  //       csrf_token: csrf_token,
  //       access_token: access_token,
  //     });
  //   }
  // }, []);

  // React.useEffect(() => {
  //   console.log('csrf_token', csrf_token);
  // }, [csrf_token]);

  // console.log('queryParams', Intl.DateTimeFormat().resolvedOptions().timeZone);

  return (
    <Router>
      <React.Suspense fallback={<div>{null}</div>}>
        <ScrollToTop updateWithPath />
        {!isNull(auth?.token) ? <AuthentictedApp /> : <UNAuthenticatedApp />}
      </React.Suspense>
    </Router>
  );
}

export default App;
