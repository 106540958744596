import axios from 'axios';
import { getLocalStorageItem } from 'hooks/use-local-storage';
import { BASE_API_ENDPOINT } from 'utils/constants/env-keys';
import { CLIENT_ID } from 'utils/constants/storage-keys';

export function getCookie(cname) {
  // from https://www.w3schools.com/js/js_cookies.asp
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export const API_CLIENT_ID = getLocalStorageItem(CLIENT_ID);

export const http = axios.create({
  baseURL: `${BASE_API_ENDPOINT}/api`,
  // headers: {
  //   Authorization: `Bearer ${BASE_APP_TOKEN}`,
  //   // 'Content-Type': 'application/json',
  //   // 'X-XSRF-TOKEN': getCookie('csrf_tkn'),
  // },
  // withCredentials: true,
  // origin: true, // research about this
  params: {
    client_id: getLocalStorageItem(CLIENT_ID),
  },
});

export const httpWithoutClient = axios.create({
  baseURL: `${BASE_API_ENDPOINT}/api`,
  // headers: {
  //   Authorization: `Bearer ${BASE_APP_TOKEN}`,
  //   // 'Content-Type': 'application/json',
  //   // 'X-XSRF-TOKEN': getCookie('csrf_tkn'),
  // },
  // withCredentials: true,
  // origin: true, // research about this
});

export const clientID = '951887aa-45d8-41aa-8f68-cd35c01dd140';

export default http;
