import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import FontFaceObserver from 'fontfaceobserver';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ChakraProvider } from '@chakra-ui/react';
import { Global, css } from '@emotion/react';

import appTheme from 'styles/theme';
import fonts from 'styles/font-face';
// import './styles/date-picker.scss';

import Head from 'components/Head';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AuthProvider } from 'containers/Auth/context';

const aller300 = new FontFaceObserver('Aller', { weight: 300 });
const aller400 = new FontFaceObserver('Aller', { weight: 400 });
const aller700 = new FontFaceObserver('Aller', { weight: 700 });

// When Aller is loaded, add a font-family using Aller to the body
Promise.all([aller300.load(), aller400.load(), aller700.load()]).then(() => {
  document.body.classList.add('fontLoaded');
});

// eslint-disable-next-line import/prefer-default-export
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      staleTime: 500000,
      refetchOnWindowFocus: false,
    },
  },
});

const render = () => {
  // eslint-disable-next-line global-require
  const App = require('./containers/App').default;

  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen position='bottom-left' />
      <AuthProvider>
        <Head />
        <ChakraProvider theme={appTheme}>
          <Global
            styles={css`
              ${fonts}
              #root {
                transition: opacity 120ms ease 0s;
              }
            `}
          />
          <App />
        </ChakraProvider>
      </AuthProvider>
    </QueryClientProvider>,
    document.getElementById('root'),
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./containers/App', render);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
