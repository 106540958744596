import * as React from 'react';
import http, { httpWithoutClient } from 'api/axios';
import { Redirect, Route, useLocation } from 'react-router-dom';
import useLocalStorage from 'hooks/use-local-storage';

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const [token, setToken] = useLocalStorage('tkn', null);
  // const removeToken = setToken(null);
  const value = { token, setToken };

  http.interceptors.request.use(
    config => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    error => Promise.reject(error),
  );

  httpWithoutClient.interceptors.request.use(
    config => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    error => Promise.reject(error),
  );

  React.useEffect(() => {
    window.addEventListener('storage', event => {
      // check if the Key that changed matches our auth key
      if (event.key === 'tkn') {
        const authToken = JSON.parse(event.newValue);
        setToken(authToken);
      }
    });
    return () =>
      window.removeEventListener('storage', event => {
        // check if the Key that changed matches our auth key
        if (event.key === 'tkn') {
          const authToken = JSON.parse(event.newValue);
          setToken(authToken);
        }
      });
  }, []);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

function useAuth() {
  return React.useContext(AuthContext);
}

function PrivateRoute({ children, ...rest }) {
  const auth = useAuth();
  const location = useLocation();

  return (
    <Route {...rest}>
      {auth.token ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      )}
    </Route>
  );
}

export { AuthProvider, useAuth, PrivateRoute };
