/* eslint-disable import/no-unresolved */
import { extendTheme } from '@chakra-ui/react';
import { isDark } from '@chakra-ui/theme-tools';
import { theme } from '@workpay/theme';
import randomColorFromString from 'utils/helpers/color';

const config = {
  useSystemColorMode: false,
  initialColorMode: 'light',
};

const avatarBlendColors = props => {
  const { name, theme: mytheme } = props;
  const bg = name ? randomColorFromString(name, '0.25') : 'gray.400';

  let color = randomColorFromString(name);

  const isBgDark = isDark(bg)(mytheme);
  if (!isBgDark) color = 'gray.800';
  return { bg, color };
};

const customTheme = {
  ...theme,
  fonts: {
    body: `'Aller', -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
    heading: `'Aller', -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
  },
  fontWeights: {
    light: 300,
    normal: 400,
    bold: 700,
  },
  layerStyles: {
    heading: {
      fontSize: '32px',
      lineHeight: '45px',
      color: 'charcoal',
      fontWeight: 'normal',
      letterSpacing: '-0.019375em',
    },
    'sub-heading': {
      // Check Getting Started Page onboarding
      fontWeight: 'normal',
      fontSize: 'md',
      color: 'onyx',
      lineHeight: '24px',
    },
    'sub-heading-text': {
      // Check Getting Started Page onboarding
      color: 'skeletonBlack',
      fontSize: 'sm',
      fontWeight: 'light',
      letterSpacing: '-0.1px',
    },
    'sub-heading-large': {
      // Individual settings
      fontWeight: 'normal',
      fontSize: 'lg',
      lineHeight: '12px',
      color: 'charcoal',
    },
    'sub-heading-large-text': {
      // Individual settings
      color: 'slate',
      fontSize: 'sm',
      fontWeight: 'light',
      lineHeight: '12px',
    },
    'form-label-text': {
      // All things formlabel, on inputs, selects and other lookalike texts
      color: 'skeletonBlack',
      fontSize: 'sm',
      fontWeight: 'normal',
      textTransform: 'uppercase',
    },
    'form-input': {
      // fontWeight: 'light',
      // fontSize: 'md !important',
      // color: '#323232',
      // borderColor: '#ACACAC !important',
      // borderRadius: 'sm',
      // px: 3,
      // py: 5,
    },
    'table-heading': {
      fontSize: 'xs',
      lineHeight: '14px',
      letterSpacing: '0.33px',
      fontWeight: 'bold',
      color: 'navy',
      textTransform: 'capitalize',
    },
    'first-table-column': {
      fontWeight: 'normal',
      fontSize: 'xs',
      color: 'slate',
    },
    'first-table-column-id': {
      fontWeight: 'normal',
      fontSize: 'xs',
      color: '#62A446',
    },
    'table-text': {
      fontWeight: 'normal',
      fontSize: 'xs',
      color: 'text',
      textTransform: 'capitalize',
    },
    'table-text-bold': {
      fontWeight: 'normal',
      fontSize: 'xs',
      color: 'skeletonBlack',
    },
    'table-row-sub-heading': {
      fontWeight: 'normal',
      fontSize: '10px',
      color: 'stoneDark',
    },
    'table-row-text': {
      fontWeight: 'normal',
      fontSize: 'xs',
      color: 'text',
      lineHeight: '16px',
      textTransform: 'capitalize',
    },
    'solid-btn': {
      fontWeight: 'light',
      fontSize: 'sm',
      color: '#FFFFFF',
      textTransform: 'uppercase',
    },
    'outline-btn': {
      fontWeight: 'light',
      fontSize: 'sm',
      textTransform: 'uppercase',
    },
    'text-regular': {
      fontWeight: 'regular',
      fontSize: 'xs',
      color: 'skeletonBlack',
    },
    'text-regular-caps': {
      color: 'skeletonBlack',
      fontSize: 'xs',
      fontWeight: 'regular',
      textTransform: 'uppercase',
    },
  },
  colors: {
    ...theme.colors,
    text: '#323232',
    'wp-purple': '#6554C0',
    slate: '#536171',
    onxy: '#2A3039',
    background: '#F2F2F2',
    overlay: '#363A40',
    skeletonGrey: '#E3E5E8',
    skeletonDark: '#BBBFC4',
    skeletonBorder: '#ACACAC',
    skeletonBlack: '#72777B',
    skeletonTable: '#EBECEC',
    snowWhite: '#FFFFFF',
    stoneDark: '#173E6799',
    'green.500': theme.colors['hue-green'][500],
  },
  components: {
    ...theme.components,
    Input: {
      parts: ['field'],
      variants: {
        outline: {
          field: {
            fontSize: 'sm',
            color: 'text',
            borderColor: 'skeletonBorder',
            borderRadius: 'base',
            _placeholder: {
              color: 'skeletonBorder',
            },
            _hover: {
              borderColor: 'skeletonBorder',
            },
            _focus: {
              borderColor: 'green',
              boxShadow: `0 0 0 1px ${theme.colors.green}`,
            },
          },
        },
      },
    },
    NumberInput: {
      parts: ['field'],
      defaultProps: {
        focusBorderColor: 'green',
      },
      variants: {
        outline: {
          field: {
            fontSize: 'sm',
            color: 'text',
            borderColor: 'skeletonBorder',
            borderRadius: 'base',
          },
        },
      },
    },
    Popover: {
      parts: ['popper'],
      baseStyle: props => ({
        popper: {
          zIndex: 10,
          maxW: props.width ? props.width : 'xs',
          w: '100%',
        },
      }),
    },
    Modal: {
      parts: ['overlay', 'dialog', 'header', 'closeButton', 'footer'],
      baseStyle: {
        overlay: {
          bg: 'rgba(15, 15, 15, 0.3)',
          backdropFilter: 'blur(5px)',
          '-webkit-backdrop-filter': 'blur(5px)',
        },
        dialog: {
          borderRadius: 'xl',
          boxShadow: '0px 3px 13px rgba(0, 0, 0, 0.08)',
        },
        header: {
          fontSize: '28px',
          lineHeight: '0.8',
          fontWeight: 'normal',
          color: 'charcoal',
          pt: '28px',
          pb: '20px',
        },
        closeButton: {
          borderRadius: '9999px',
          fontSize: 'xs',
          color: 'charcoal',
          _hover: {
            bg: 'blackAlpha.50',
          },
          _focus: {
            boxShadow: '0 0 0 3px white !important',
          },
        },
        footer: {
          py: '1.5rem',
          button: {
            width: '130px',
            textTransform: 'uppercase',
          },
        },
      },
      sizes: {
        md: {
          dialog: {
            maxW: '630px',
          },
        },
        sm: {
          dialog: {
            maxW: '468px',
          },
        },
      },
    },
    Stat: {
      parts: ['label', 'number'],
      baseStyle: {
        label: {
          fontWeight: 'light',
          color: 'text',
          lineHeight: 'tall',
          fontSize: 'xs',
          // textTransform: 'uppercase',
        },
        number: {
          fontWeight: 'light',
          color: 'green',
          lineHeight: 'none',
        },
      },
    },
    FormLabel: {
      baseStyle: {
        width: 'fit-content',
        color: 'skeletonBlack',
        fontSize: 'sm',
        textTransform: 'uppercase',
        fontWeight: 'normal',
      },
      sizes: {
        sm: {
          fontSize: 'xs',
        },
      },
      defaultProps: { size: 'sm' },
    },
    Menu: {
      parts: ['item', 'list'],
      baseStyle: {
        list: {
          px: '4px',
          borderRadius: 'md',
          backgroundColor: 'white',
          boxShadow: '0px 3px 13px #00000014',
          zIndex: 'dropdown',
        },
        item: {
          fontSize: 'sm',
          borderRadius: 'base',
          cursor: 'pointer',
          color: 'skeletonBlack',
          _hover: {
            backgroundColor: 'background',
          },
          _focus: {
            backgroundColor: 'background',
          },
        },
      },
      defaultProps: { isLazy: true, lazyBehavior: 'unmount' },
    },
    Badge: {
      baseStyle: {
        textTransform: 'capitalize',
        fontWeight: 'light',
        borderRadius: '5px',
      },
    },
    Textarea: {
      variants: {
        outline: {
          fontSize: 'sm',
          borderColor: 'skeletonBorder',
          borderRadius: 'base',
          _hover: {
            borderColor: 'skeletonBorder',
          },
          _focus: {
            boxShadow: 'none',
            borderColor: 'skeletonBorder',
          },
        },
      },
    },
    Table: {
      baseStyle: {
        th: {
          fontSize: 'xs',
          lineHeight: '14px',
          letterSpacing: '0.33px',
          fontWeight: 'bold',
          color: 'slate',
          textTransform: 'capitalize',
        },
      },
    },
    Switch: {
      defaultProps: { size: 'sm', variant: 'primary' },
      baseStyle: {
        track: {
          backgroundColor: 'skeletonDark',
        },
      },
      parts: ['track', 'thumb'],
      sizes: {
        sm: {
          thumb: {
            _checked: {
              transform: 'translateX(1rem)',
            },
          },
          track: {
            w: '28px',
            _focus: {
              boxShadow: 'none',
            },
          },
        },
      },
      variants: {
        primary: {
          track: {
            _checked: {
              backgroundColor: 'green',
            },
          },
        },
        secondary: {
          track: {
            _checked: {
              backgroundColor: 'skeletonBlack',
            },
          },
        },
      },
    },
    Checkbox: {
      defaultProps: { size: 'md', colorScheme: 'hue-green' },
      parts: ['container', 'control', 'label', 'icon'],
      sizes: {
        md: {
          control: {
            borderColor: 'skeletonDark',
            boxShadow: 'none !important',
          },
          label: {
            fontSize: 'sm',
            // color: 'skeletonBlack',
          },
        },
      },
    },
    Alert: {
      parts: ['container', 'title', 'description', 'icon'],
      baseStyle: {
        container: {
          borderRadius: 'base',
        },
        description: {
          fontSize: 'sm',
        },
        icon: {
          borderRadius: '9999px',
          fontSize: 'xs',
          color: 'skeletonBlack',
          // _hover: {
          //   bg: 'blackAlpha.50',
          // },
          _focus: {
            boxShadow: '0 0 0 3px white !important',
          },
        },
      },
    },
    CloseButton: {
      baseStyle: {
        borderRadius: '9999px',
        fontSize: 'xs',
        color: 'skeletonBlack',
        _hover: {
          bg: 'blackAlpha.50',
        },
        _focus: {
          boxShadow: '0 0 0 3px white !important',
        },
      },
    },
    Tooltip: {
      baseStyle: {
        borderRadius: 'base',
        bgColor: 'white',
        color: 'text',
        fontSize: '13px',
      },
      variants: {
        'permission-denied': {
          maxW: '200px',
          placement: 'top',
        },
      },
    },
    Drawer: {
      parts: ['DrawerCloseButton'],
      baseStyle: {
        dialog: {
          background: '#F2F2F2',
          boxShadow: '0px 15px 20px #00000029',
        },
        DrawerCloseButton: {
          border: 'transparent',
        },
      },
    },
    Skeleton: {
      defaultProps: {
        startColor: 'skeletonTable',
        endColor: 'skeletonDark',
      },
      baseStyle: {
        width: 'fit-content',
      },
    },
    Button: {
      ...theme.components.Button,
      defaultProps: {
        ...theme.components.Button.defaultProps,
        size: 'sm',
        variant: 'solid',
      },
      variants: {
        white: {
          bgColor: 'white',
          color: 'text',
        },
      },
    },
    Avatar: {
      baseStyle: props => ({
        container: { background: avatarBlendColors(props).bg },
        label: { color: avatarBlendColors(props).color },
      }),
      defaultProps: {
        size: 'sm',
      },
    },
  },
};

export const appTheme = extendTheme({
  config,
  ...customTheme,
});

export const { colors, DateWrapper } = appTheme;

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-console
  console.log('appTheme', appTheme, theme);
}

export default appTheme;
